 
$( 'img.lazy' ).lazyload({
  threshold: 2000 ,			// 200pxの距離まで近づいたら表示する
  effect: "fadeIn" ,		// じわじわと表示させる
  effect_speed: 0 ,		// 3秒かけて表示させる
});

$('.news_slider').slick({
  infinite: true,
  arrows: true,
  dots: false,
  vertical: true,
  slidesToShow: 1,
  slidesToScroll: 1,
});

$('.slider_1').slick({
  infinite: true,
  arrows: true,
  centerMode:	true,
  centerPadding: '0',
  slidesToShow: 5,
  slidesToScroll: 1,
  adaptiveHeight: false,
  autoplay: true,
  autoplaySpeed: 5000,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '10vw',
      }
    }
  ]
});

$('.mv_sld').slick({
  infinite: false,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
});

if (matchMedia('only screen and (max-width: 767px)').matches) {
//スマホ・タブレットの時の処理
  $(function () {
    $(window).scroll(function () {
      $('.Fade-In,.Fade-In-Down,.Fade-In-Left,.Fade-In-Right').each(function () {
      var targetElement = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > targetElement - windowHeight + -1000) {
        $(this).css('opacity', '1');
        $(this).css('transform', 'translateY(0)');
      }
      });
    });
  });
  $(".sp_none").removeClass("Fade-In");
} else if (matchMedia('only screen and (max-width: 991px)').matches) {
  //タブレットの時の処理
  $(function () {
    $(window).scroll(function () {
      $('.Fade-In,.Fade-In-Down,.Fade-In-Left,.Fade-In-Right').each(function () {
        var targetElement = $(this).offset().top;
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        if (scroll > targetElement - windowHeight + -300) {
          $(this).css('opacity', '1');
          $(this).css('transform', 'translateY(0)');
        }
      });
    });
  });
} else {
//PCの時の処理
  $(function () {
    $(window).scroll(function () {
      $('.Fade-In,.Fade-In-Down,.Fade-In-Left,.Fade-In-Right').each(function () {
        var targetElement = $(this).offset().top;
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        if (scroll > targetElement - windowHeight + 100) {
          $(this).css('opacity', '1');
          $(this).css('transform', 'translateY(0)');
        }
      });
    });
  });
};


var gNavVisible = false;
$('.sp_menu').on('click', function(){
  $(this).stop().toggleClass('active');
  if (gNavVisible) {
    $('.g_nav').stop().toggleClass('active').fadeOut(500);
  } else {
      $('.g_nav').stop().toggleClass('active').fadeIn(500);
  }
  gNavVisible = !gNavVisible;
});

$('.dl_faq dt').on('click', function(){
  $(this).toggleClass('active').next('dd').stop().slideToggle();
});
